<template>
  <div class="min-h-screen flex flex-col">
    <NuxtLayout>
      <div class="flex flex-col items-center md:items-start gap-2">
        <div class="text-5xl">😯</div>
        <div
          v-if="error.statusCode === 404"
          class="flex flex-col gap-1 items-center md:items-start">
          <h1 class="text-2xl text-center md:text-start">
            This page does not exist
          </h1>
          <p class="text-center md:text-start">
            The page you are looking for either does not exist or it has been
            deleted.
          </p>
        </div>
        <div v-else class="flex flex-col gap-1 items-center md:items-start">
          <h1 class="text-2xl text-center md:text-start">
            An unexpected error has occured
          </h1>
          <p class="text-center md:text-start">
            Sorry, this is quite embarrassing.
          </p>
          <p class="text-center md:text-start">
            <NuxtLink class="link" @click="goBack">Go back</NuxtLink> and retry
            the action that brought you here after a few seconds.
          </p>
        </div>
      </div>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
  import type { NuxtError } from "#app";

  const props = defineProps<{
    error: NuxtError;
  }>();

  const router = useRouter();
  const goBack = () => nextTick(() => router.back());

  console.error(props.error);
</script>
