<template>
  <div :class="maxw" class="text-ellipsis overflow-hidden whitespace-nowrap">
    {{ value }}
  </div>
</template>

<script setup lang="ts">
  import { useWindowSize } from "@vueuse/core";

  const props = withDefaults(
    defineProps<{
      maxLength?: number;
      value?: string;
    }>(),
    {
      value: "",
      maxLength: 200,
    }
  );

  const { width } = useWindowSize();
  const maxw = computed(
    () => `max-w-[${width.value > 750 ? 400 : props.maxLength}px]`
  );
</script>
